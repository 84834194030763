import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Grid,
    Paper,
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { Tip } from '../../Help';
import { BoxContainer, Button, CircularProgress, FormControl, TextField } from '../styled';

interface DataProps {
    processing?: boolean;
    // apiStatus?: number;
    onSubmit: (userId: string) => Promise<any[]>;
}
export default ({ onSubmit, processing }: DataProps) => {
    const [userId, setUserId] = useState('');
    const [transactions, setTransactions] = useState<any[]>([]);
    const resetForm = useCallback(() => {
        setUserId('');
        const clearButtons = document.getElementsByClassName(
            'MuiAutocomplete-clearIndicator'
        ) as unknown as HTMLButtonElement[];
        clearButtons[0].click();
        clearButtons[1].click();
    }, []);

    const submit = useCallback(async () => {
        if (userId) {
            const result = await onSubmit(userId);
            if (result) {
                setTransactions(result);
            }
        }
    }, [userId, resetForm]);

    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Recurring Transactions</title>
            </Helmet>

            <BoxContainer container spacing={3}>
                <Grid item xs={12} sm={12} md={9}>
                    <Paper elevation={1}>
                        <Container>
                            <Typography variant="h6">Get Recurring</Typography>

                            <FormControl variant="outlined">
                                <TextField
                                    onChange={e => setUserId(e.target.value)}
                                    variant="outlined"
                                    disabled={processing}
                                    placeholder="User ID"
                                    size="small"
                                    value={userId}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={submit}
                                    disabled={!userId}
                                >
                                    {processing ? <CircularProgress color="inherit" /> : 'Submit'}
                                </Button>
                            </FormControl>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Memo</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                            <TableCell align="right">Interval</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                            <TableCell align="right">Dates</TableCell>
                                            <TableCell align="right">Category</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactions
                                            .sort(
                                                (a, b) =>
                                                    b.transactions.length - a.transactions.length
                                            )
                                            .map(row => (
                                                <TableRow key={row.memo}>
                                                    <TableCell component="th" scope="row">
                                                        {row.memo}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.transactions.length}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.interval}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.transactions[0].value}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.transactions
                                                            .map(t => t.transactedAt)
                                                            .slice(0, 3)
                                                            .join(', ')}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {JSON.stringify(
                                                            row.transactions[0]
                                                                .personalFinanceCategory,
                                                            null,
                                                            2
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                    <Tip>
                        <Typography variant="body2" component="p">
                            Use this screen to write test data.
                        </Typography>

                        <Typography variant="body2" component="p">
                            Click on submit once all fields are filled.
                        </Typography>
                    </Tip>
                </Grid>
            </BoxContainer>
        </>
    );
};
